import axios from "axios";
import {
  handleResponse,
  authHeader,
  authBody,
  authFileHeader,
} from "../../library/handler";

export async function getPatientBasicById(state, uuid) {
  return fetch(
    `${process.env.REACT_APP_APP_API}/patients/patient/${uuid}/basic`,
    {
      method: "get",
      headers: await authHeader(state),
    }
  )
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
}

export async function getPatientAppointmentsById(state, uuid) {
  return fetch(
    `${process.env.REACT_APP_APP_API}/patients/patient/${uuid}/appointments`,
    {
      method: "get",
      headers: await authHeader(state),
    }
  )
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
}

export async function getPatientInvoicesById(state, uuid) {
  return fetch(
    `${process.env.REACT_APP_APP_API}/patients/patient/${uuid}/invoices`,
    {
      method: "get",
      headers: await authHeader(state),
    }
  )
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
}

export async function getPatientChargesById(state, uuid) {
  return fetch(
    `${process.env.REACT_APP_APP_API}/patients/patient/${uuid}/charges`,
    {
      method: "get",
      headers: await authHeader(state),
    }
  )
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
}

export async function getPatientPaymentsById(state, uuid) {
  return fetch(
    `${process.env.REACT_APP_APP_API}/patients/patient/${uuid}/payments`,
    {
      method: "get",
      headers: await authHeader(state),
    }
  )
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
}

export async function getPatientNotesById(state, uuid) {
  return fetch(
    `${process.env.REACT_APP_APP_API}/patients/patient/${uuid}/notes`,
    {
      method: "get",
      headers: await authHeader(state),
    }
  )
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
}

export async function getPatientProceeduresById(state, uuid) {
  return fetch(
    `${process.env.REACT_APP_APP_API}/patients/patient/${uuid}/proceedures`,
    {
      method: "get",
      headers: await authHeader(state),
    }
  )
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
}

export const getPatients = async (state) => {
  return fetch(`${process.env.REACT_APP_APP_API}/patients`, {
    method: "get",
    headers: await authHeader(state),
  })
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
};

export const getPatientsSms = async (state, limit = 2000, offset = 0) => {
  return fetch(`${process.env.REACT_APP_APP_API}/patients/sms/${limit}/${offset}`, {
    method: "get",
    headers: await authHeader(state),
  })
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
};

export const getPatientsMessages = async (state, uuid) => {
  return fetch(`${process.env.REACT_APP_APP_API}/patients/messages/${uuid}`, {
    method: "get",
    headers: await authHeader(state),
  })
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
};

export const createPatient = async (state, data) => {
  return fetch(`${process.env.REACT_APP_APP_API}/patients/create`, {
    method: "post",
    body: JSON.stringify(authBody(data, state)),
    headers: await authHeader(state),
  })
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
};

export const updatePatient = async (state, data, uuid) => {
  return fetch(`${process.env.REACT_APP_APP_API}/patients/update/${uuid}`, {
    method: "put",
    body: JSON.stringify(data),
    headers: await authHeader(state),
  })
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
};

export const updateOnePatient = async (state, data, uuid) => {
  return fetch(
    `${process.env.REACT_APP_APP_API}/patients/patient/update/${uuid}`,
    {
      method: "put",
      body: JSON.stringify(data),
      headers: await authHeader(state),
    }
  )
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
};

export const deletePatient = async (state, uuid) => {
  return fetch(`${process.env.REACT_APP_APP_API}/patients/${uuid}`, {
    method: "delete",
    headers: await authHeader(state),
  })
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
};

export const filesUpload = async (state, data, user, onUploadProgress) =>
  axios.post(`${process.env.REACT_APP_APP_API}/files/upload`, data, {
    headers: {
      ...authFileHeader(state),
      patient: JSON.stringify(user),
    },
    onUploadProgress,
  });

export const getFile = async (state, data) =>
  fetch(`${process.env.REACT_APP_APP_API}/files/get/file`, {
    method: "post",
    headers: await authHeader(state),
    body: JSON.stringify(data),
  }).then((response) => response.blob());
