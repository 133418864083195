import React, { useContext, useEffect, useState } from "react";
import store from "store";
import { getTreatments } from "actions/treatments";
import { getSchedules } from "actions/schedules";
import { getAppointmentEvents, getAppointments } from "actions/appointments";
import { getPatients, getPatientsSms } from "actions/patients";
import { getPractitioners } from "actions/practitioners";
import { getCategories } from "actions/categories";
import { fetcNotifications } from "actions/notifications";
import { getAuthPractice, getLastEventUuid } from "actions/practices";
import {
  getItem,
  updateList,
  updateListFromCache,
  updateObjectFromCache,
} from "library/resources";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  LinearProgress,
} from "@mui/material";
import { AppContext } from "hooks/context";
import { ADMIN_NS } from "config";
import { endOfMonth, format, startOfMonth, addMonths } from "date-fns";
import localforage from "localforage";
import stateHandler from "hooks/states/stateHandler";
import { getBatchRecall } from "actions/sms";

const MONTH_LOAD = 3;

function isAuthenticated(state) {
  if (!store.get(state)) return false;
  const { user } = store.get(state);
  const now = new Date().getTime();
  const expire = new Date(user.data.exp * 1000).getTime();
  return now < expire;
}

const fetchAppointmentsForMonth = async (monthOffset) => {
  const startDate = format(startOfMonth(addMonths(new Date(), monthOffset)), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");
  const endDate = format(endOfMonth(addMonths(new Date(), monthOffset)), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");

  let offset = 0;
  let allData = [];
  const limit = 100;

  while (true) {
    const appointments = await getAppointments(ADMIN_NS, startDate, endDate, limit, offset);
    if (!appointments?.data?.length) break;
    allData.push(...appointments.data);
    offset += limit;
  }

  const events = await getAppointmentEvents(ADMIN_NS, startDate, endDate);
  allData.push(...events.data);

  return allData;
};


async function loadAppointmentsConcurrently() {
  const promises = [];

  for (let monthOffset = -1; monthOffset <= MONTH_LOAD; monthOffset++) {
    // Instead of awaiting inside the loop, we push each promise into an array
    promises.push(fetchAppointmentsForMonth(monthOffset));
  }

  // Using Promise.all to wait for all fetches to complete
  const results = await Promise.all(promises);

  // Flatten the results array to get allAppointmentsAndEvents
  const allAppointmentsAndEvents = [].concat(...results);

  // Dispatch the combined data or use it as required
  return {
    code: "SUCCESS",
    data: allAppointmentsAndEvents,
  };
}

const DataLoader = () => {
  const { dispatch, state, app } = useContext(AppContext);
  const [dataText, setDataText] = useState(null);
  const [loaded, setLoaded] = useState(100);
  const [syncOpen, setSyncOpen] = useState(false);
  const [lostConnectionOpen, setLostConnectionOpen] = useState(false);

  async function initData() {
    const auth = await isAuthenticated(ADMIN_NS);
    if (!auth) {
      location.href = "/#/admin/logout";
    }

    const pageLoaded = sessionStorage.getItem("pageLoaded");
    console.log("Page loaded: ", pageLoaded);

    if (!pageLoaded) {
      const lastConnectUuid = await getLastEventUuid(ADMIN_NS);
      let storeState = store.get(ADMIN_NS);
      setSyncOpen(true);

      setLoaded(0);
      setDataText("all calendar appointments");
      const updateAppointments = loadAppointmentsConcurrently();
      const updatePractitioners = updateList(
        "list",
        getPractitioners
      )(dispatch);
      const updatePatients = updateList("patients", getPatients)(dispatch);
      const updateSms = updateList("sms", async () => {
        // looop over until all sms are fetched
        let offset = 0;
        let limit = 2000;
        const sms = await getPatientsSms(ADMIN_NS, limit, offset);
        return {
          code: "SUCCESS",
          data: sms.data,
        };
      })(dispatch);

      const updateSchedules = updateList("schedules", getSchedules)(dispatch);

      const updateTreatments = updateList(
        "treatments",
        getTreatments
      )(dispatch);

      const updateCategories = updateList(
        "categories",
        getCategories
      )(dispatch);

      const updateNotifications = updateList(
        "notifications",
        fetcNotifications
      )(dispatch);

      const updatePractice = getItem("practice", getAuthPractice)(dispatch);

      const updateBatchRecall = updateList(
        "batchqueues",
        getBatchRecall
      )(dispatch);

      const appointments = await updateAppointments;
      await localforage.setItem("appointments", appointments.data);
      dispatch({
        type: "LOAD",
        context: "appointments",
        data: appointments.data,
      });

      setLoaded(15);
      setDataText("your practitioner data");
      await updatePractitioners;

      setLoaded(30);
      setDataText("the patients data");
      await updatePatients;

      setLoaded(40);
      setDataText("the sms data");
      await updateSms;

      await setLoaded(45);
      setDataText("the schedules data");
      await updateSchedules;

      setLoaded(50);
      setDataText("the treatments data");
      await updateTreatments;

      setLoaded(70);
      await updateCategories;

      setLoaded(80);
      setDataText("the notifications data");
      await updateNotifications;

      setLoaded(90);
      setDataText("the practice data");
      await updatePractice;

      setLoaded(95);
      await updateBatchRecall;

      await setLoaded(100);
      setSyncOpen(false);
      setDataText(null);

      let indexedDates = [];
      for (let monthOffset = -1; monthOffset <= MONTH_LOAD; monthOffset++) {
        const date = new Date();
        date.setMonth(date.getMonth() + monthOffset);
        indexedDates.push(format(date, "yyyy-MM"));
      }

      stateHandler.meta.update({
        lastConnectUuid: lastConnectUuid?.lastEventUuid,
        indexedDates,
      })(dispatch);

      sessionStorage.setItem("pageLoaded", "true");
    } else {
      conssole.log("Page load without refresh detected");
      await updateListFromCache("appointments")(dispatch);
      await updateListFromCache("list")(dispatch);
      await updateListFromCache("patients")(dispatch);
      await updateListFromCache("sms")(dispatch);
      await updateListFromCache("schedules")(dispatch);
      await updateListFromCache("treatments")(dispatch);
      await updateListFromCache("categories")(dispatch);
      await updateObjectFromCache("practice")(dispatch);
      await updateObjectFromCache("meta")(dispatch);
    }
  }

  useEffect(async () => {
    const handleBeforeUnload = (e) => {
      localforage.clear();
      sessionStorage.removeItem("pageLoaded");
    };
    const pageLoaded = sessionStorage.getItem("pageLoaded");
    console.log("Page loaded: ", pageLoaded);
    if (!pageLoaded) {
      await initData();
      console.log("Page load or refresh detected");
    }
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (state?.meta?.data?.disconnected) {
      setLostConnectionOpen(true);
    } else {
      setLostConnectionOpen(false);
    }
  }, [state?.meta?.data?.disconnected]);

  if (!!syncOpen)
    return (
      <SynchronizationDialog
        open={syncOpen}
        loaded={loaded}
        dataText={dataText}
      />
    );

  if (!!lostConnectionOpen)
    return (
      <LostConnectionDialog open={lostConnectionOpen} dataText={dataText} />
    );

  return <></>;
};

const SynchronizationDialog = ({ open, loaded, dataText }) => (
  <Dialog open={open} color="primary" maxWidth="lg" fullWidth>
    <LinearProgress variant="buffer" value={loaded} valueBuffer={loaded + 10} />
    <div style={{ textAlign: "center" }}>
      <DialogTitle>{"Data Synchronization with the cloud!"}</DialogTitle>
      <DialogContent>
        <DialogContentText>Please wait while we are</DialogContentText>
        <DialogContentText>
          synchronizing <strong>{dataText}</strong> with the server ...
        </DialogContentText>
      </DialogContent>
    </div>
    <LinearProgress variant="buffer" value={loaded} valueBuffer={loaded + 10} />
  </Dialog>
);

const LostConnectionDialog = ({ open }) => (
  <Dialog open={open} color="primary" maxWidth="lg" fullWidth>
    <LinearProgress color="primary" />
    <div style={{ textAlign: "center" }}>
      <DialogTitle>{"Data Synchronization with the cloud!"}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please wait while we are fetching updates from the server ...
        </DialogContentText>
      </DialogContent>
    </div>
    <LinearProgress color="primary" />
  </Dialog>
);

export default DataLoader;
