import React, { useState, useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import localforage from "localforage";
import store from "store";
import { ADMIN_NS, PUBLIC_NS } from "config";

function UpdateNotifier() {
  const [currentVersion, setCurrentVersion] = useState(null);
  const [latestVersion, setLatestVersion] = useState(null);

  // Load current version on mount
  useEffect(() => {
    fetch("/manifest.json")
      .then((response) => response.json())
      .then((data) => setCurrentVersion(data.version));
  }, []);

  // Periodically check for new version
  useEffect(() => {
    const interval = setInterval(() => {
      fetch("/version.json?ts=" + Date.now())
        .then((response) => response.json())
        .then((data) => setLatestVersion(data.version));
    }, 60000); // check every 60 seconds, adjust as needed

    return () => clearInterval(interval);
  }, []);

  if (currentVersion && latestVersion && currentVersion !== latestVersion) {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={open}
      >
        <Alert severity="info">
          <AlertTitle>Update Available</AlertTitle>
          <p>
            A new version of this app is available. Please update to the latest
            version. {currentVersion} → <strong>{latestVersion}</strong>
            <Button
              color="secondary"
              size="small"
              onClick={() => {
                store.remove(ADMIN_NS);
                store.remove(PUBLIC_NS);
                localforage.clear().then(() => {
                  window.location.reload(true);
                });
              }}
            >
              UPDATE NOW
            </Button>{" "}
          </p>
        </Alert>
      </Snackbar>
    );
  }

  return <></>;
}

export default UpdateNotifier;
